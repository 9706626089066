import React from 'react';
import 'moment-timezone';
import moment from "moment";
import './timeline-tab.scss'
import Moment from "react-moment";


function TimelineTab({isActive, audits, user_name}) {
    audits = audits.sort(function(a,b){
        return new Date(b.created_at) - new Date(a.created_at);
    });

    const groupedByDate = audits.reduce((auditDate, audit) => {
        const date = moment(audit.created_at).format('D MMM YYYY');
        if (!auditDate[date]) auditDate[date] = [];
        auditDate[date].push(audit);
        return auditDate;
    }, {});

    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            {/* The timeline */}
            {audits.length === 0 && <div>This user has not logged in yet.</div>}
            <div className="timeline">
                {
                    audits.length > 0 &&
                    Object.keys(groupedByDate).map(function(date) {
                        const bg_colors = ['bg-red', 'bg-success', 'bg-warning', 'bg-primary'];
                        return (
                            <React.Fragment key={date}>
                                <div className="time-label"><span className={bg_colors[Math.floor(Math.random()*bg_colors.length)]}>{date}</span></div>
                                {
                                    groupedByDate[date].map(function (audit) {
                                        if (audit.event === "Logged In") {
                                            return (
                                                <div key={audit.id}>
                                                    <i className="fas fa-unlock bg-success" />
                                                    <div className="timeline-item">
                                                    <span className="time">
                                                        <i className="far fa-clock" />
                                                        <span>
                                                            <Moment fromNow>{audit.created_at}</Moment>
                                                        </span>
                                                    </span>
                                                        <h3 className="timeline-header">
                                                            <span>Logged in</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        if(audit.event === "Logged Out") {
                                            return (
                                                <div key={audit.id}>
                                                    <i className="fas fa-lock bg-danger" />
                                                    <div className="timeline-item">
                                                        <span className="time">
                                                            <i className="far fa-clock" />
                                                            <span> <Moment fromNow>{audit.created_at}</Moment></span>
                                                        </span>
                                                        <h3 className="timeline-header">
                                                            <span>Logged out</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        if(audit.event === "created" && audit.auditable_type === "App\\Models\\Sfa") {
                                            return (
                                                <div key={audit.id}>
                                                    <i className="fas fa-file-alt bg-primary" />
                                                    <div className="timeline-item">
                                                        <span className="time">
                                                            <i className="far fa-clock" />
                                                            <span> <Moment fromNow>{audit.created_at}</Moment></span>
                                                        </span>
                                                        <h3 className="timeline-header">
                                                            <span>Started a new SFA Application</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        if(audit.event === "created" && audit.auditable_type === "App\\Models\\Empower") {
                                            return (
                                                <div key={audit.id}>
                                                    <i className="nav-icon fas fa-file-alt bg-warning" />
                                                    <div className="timeline-item">
                                                        <span className="time">
                                                            <i className="far fa-clock" />
                                                            <span> <Moment fromNow>{audit.created_at}</Moment></span>
                                                        </span>
                                                        <h3 className="timeline-header">
                                                            <span>Started a new Empower Application</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </React.Fragment>)
                    })
                }

                {/*// ))*/}
                {/*<div>*/}
                {/*    <i className="far fa-clock bg-gray" />*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default TimelineTab;